import { useEffect, useState } from "react";

// SCSS
import "./Carousel.scss";

// COMPONENTS
import CarouselItem from "./CarouselItem.jsx";

// LIBRARIES
//        SWIPER
// import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { useAppContext } from "../../Context/AppContext.jsx";
import { useDataContext } from "../../Context/DataContext.jsx";

const Carousel = () => {
	const { projectType, setShowIframe } = useAppContext();

	const dataContext = useDataContext();
	const data =
		projectType === "video"
			? dataContext.videoData
			: projectType === "other"
			? dataContext.otherData
			: dataContext.webData;

	if (data.length === 0) return <h3>No items</h3>;
	if (data.length > 0 && data.length < 3)
		return data.map((item, index) => (
			<Swiper key={projectType} slidesPerView={2} spaceBetween={130} direction={"vertical"} centeredSlides={true}>
				{data.map((item, index) => {
					if (item.display === true) {
						return (
							<SwiperSlide key={index}>
								{({ isActive }) => <CarouselItem data={item} index={index} isActive={isActive} />}
							</SwiperSlide>
						);
					} else {
						return null;
					}
				})}
			</Swiper>
		));
	if (data.length >= 3)
		return (
			<div className="carousel_container">
				<Swiper key={projectType} slidesPerView={3} spaceBetween={130} direction={"vertical"} centeredSlides={true}>
					{data.map((item, index) => {
						if (item.display === true) {
							return (
								<SwiperSlide key={index}>
									{({ isActive }) => <CarouselItem data={item} index={index} isActive={isActive} />}
								</SwiperSlide>
							);
						} else {
							return null;
						}
					})}
				</Swiper>

				{/* <button onClick={() => setShowIframe((old) => !old)}>OPEN Iframe</button> */}
			</div>
		);

	// return (
	// 	<div className="carousel_container">
	// 		{data.length === 0 && <h3>No items</h3>}
	// 		{data.length >= 3 && (
	// 			<Swiper
	// 				// modules={[Autoplay]}
	// 				slidesPerView={3}
	// 				spaceBetween={80}
	// 				direction={"vertical"}
	// 				centeredSlides={true}
	// 			>
	// 				{data.map((item, index) => {
	// 					if (item.display === true) {
	// 						return (
	// 							<SwiperSlide key={index}>
	// 								{({ isActive }) => (
	// 									<CarouselItem
	// 										color={color}
	// 										data={item}
	// 										current={isActive}
	// 										index={index}
	// 										setCurrentIndex={setCurrentIndex}
	// 										block1IsOpen={block1IsOpen}
	// 										block2IsOpen={block2IsOpen}
	// 										setBlock1IsOpen={setBlock1IsOpen}
	// 										setBlock2IsOpen={setBlock2IsOpen}
	// 									/>
	// 								)}
	// 							</SwiperSlide>
	// 						);
	// 					} else {
	// 						return null;
	// 					}
	// 				})}
	// 			</Swiper>
	// 		)}
	// 		{data.length < 3 &&
	// 			data.map((item, index) => (
	// 				<CarouselItem
	// 					key={index}
	// 					color={color}
	// 					data={item}
	// 					current={false}
	// 					index={index}
	// 					setCurrentIndex={setCurrentIndex}
	// 					block1IsOpen={block1IsOpen}
	// 					block2IsOpen={block2IsOpen}
	// 					setBlock1IsOpen={setBlock1IsOpen}
	// 					setBlock2IsOpen={setBlock2IsOpen}
	// 				/>
	// 			))}
	// 	</div>
	// );
};

export default Carousel;
